// @flow

import { BroadcastChannel } from 'broadcast-channel';
import { env } from 'config/env';
import { LOG_LEVEL as CONFIG_LOG_LEVEL } from 'config';
import { datadogLogs } from '@datadog/browser-logs';
import { activeWindowListener } from './activeWindow';

export const getCircularReplacer = (): ((key: string, value: mixed) => mixed) => {
  const seen = new WeakSet();
  return (_, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export type LogLevels = 'debug' | 'info' | 'warn' | 'error' | 'fatal';
type LogLevelNumbers = 0 | 1 | 2 | 3 | 4 | 5;

const LOG_LEVEL_NUMBER = {
  debug: 0,
  info: 1,
  warn: 2,
  error: 3,
  fatal: 4,
  off: 5,
};

export const LOG_LEVELS: $ReadOnlyArray<LogLevels> = ['debug', 'info', 'warn', 'error', 'fatal'];
export const ERROR_LOG_LEVELS: $ReadOnlyArray<LogLevels> = ['error', 'fatal'];

export const CONSOLE_MAP = {
  debug: 'debug',
  info: 'info',
  warn: 'warn',
  error: 'error',
  fatal: 'error',
};

const LOG_LEVEL = LOG_LEVEL_NUMBER[CONFIG_LOG_LEVEL ?? 'info'];
const REMOTE_LOGGING = env.FORCE_REMOTE_LOGGING === 'true' || env.DEPLOY_ENV !== 'local';
function logHeader(level: LogLevels) {
  return [`%c[${level.toUpperCase()}]%c`, 'font-weight: bold', 'font-weight: normal'];
}

/**
 * If we are using this module from a worker, we want to send the logs the currently active parent
 * window. This is to make sure that the logs are visible in the browser console. We also want to
 * forward the logs to datadog since we don't have access to the Datadog logger from the worker.
 * We'll use a broadcast channel for the communication
 */
const isWorker = typeof window === 'undefined';
const channel = new BroadcastChannel('logger');

if (!isWorker) {
  const listener = ({
    level,
    args,
    stack,
  }: {
    level: LogLevels,
    args: LoggerArguments,
    stack?: string | null,
  }) => {
    if (activeWindowListener.isWindowActive) {
      let error: ?Error;
      if (stack != null) {
        const errorObject = new Error();
        errorObject.stack = stack;
        error = errorObject;
      }

      switch (level) {
        case 'debug':
          logger.debug(...args);
          break;
        case 'info':
          logger.info(...args);
          break;
        case 'warn':
          logger.warn(...args);
          break;
        case 'error':
          logger.error(...args, error);
          break;
        case 'fatal':
          logger.fatal(...args, error);
          break;
        default:
          logger.error(
            'Unknown log level:',
            level,
            'Original message:',
            args,
            'Stack trace:',
            error?.stack
          );
          break;
      }
    }
  };
  channel.addEventListener('message', listener);
}

type StackTrace = string;
type ContextObject = mixed;
type LoggerArguments =
  | mixed[]
  // Below are common call patterns that already exist in the repo, but the logger will accept any
  // combination of these elements, which is covered by mixed[] above:
  | [string]
  | [string, ContextObject]
  | [ContextObject]
  | [Error]
  | [string, Error]
  | [string, Error, StackTrace];

export class Logger {
  #remoteLogging: boolean;
  #logLevel: LogLevelNumbers;

  constructor(remoteLogging: boolean, logLevel: LogLevelNumbers) {
    this.#remoteLogging = remoteLogging;
    this.#logLevel = logLevel;
  }

  #log(level: LogLevels, ...args: LoggerArguments) {
    if (this.#logLevel > LOG_LEVEL_NUMBER[level]) return;
    const isErrorLog: boolean = ERROR_LOG_LEVELS.includes(level);
    const { message, context, error } = Logger.parseLoggerArgs(args, level);

    if (this.#remoteLogging && !isWorker) {
      // $FlowIgnore[incompatible-type] Datadog logger's third argument accepts `undefined`, but Flow doesn't recognize it
      datadogLogs.logger[CONSOLE_MAP[level]](message, context, error);
    }

    // Don't log to the console if executing in a unit test, as a `console.(warn|error)` will cause
    // tests to fail
    if (env.TEST !== 'true') {
      // eslint-disable-next-line no-console
      console[CONSOLE_MAP[level]](...logHeader(level), ...args);
    }

    if (isWorker) {
      channel.postMessage({
        level,
        args: ['(from worker)', ...args],
        stack: isErrorLog ? error?.stack : undefined,
      });
    }
  }

  /**
   * Log an optional message, optional context object, and optional error at the debug level
   *
   * Order of the context object and error is not important, all are valid:
   * - `logger.debug('message', contextObject, error)`
   * - `logger.debug('message', error, contextObject)`
   * - `logger.debug(error, contextObject)`
   *
   * All other arguments (strings, numbers, booleans) are stringified and concatenated into the message
   */
  debug(...args: LoggerArguments) {
    this.#log('debug', ...args);
  }

  /**
   * Log an optional message, optional context object, and optional error at the info level
   *
   * Order of the context object and error is not important, all are valid:
   * - `logger.info('message', contextObject, error)`
   * - `logger.info('message', error, contextObject)`
   * - `logger.info(error, contextObject)`
   *
   * All other arguments (strings, numbers, booleans) are stringified and concatenated into the message
   */
  info(...args: LoggerArguments) {
    this.#log('info', ...args);
  }

  /**
   * Log an optional message, optional context object, and optional error at the warn level
   *
   * Order of the context object and error is not important, all are valid:
   * - `logger.warn('message', contextObject, error)`
   * - `logger.warn('message', error, contextObject)`
   * - `logger.warn(error, contextObject)`
   *
   * All other arguments (strings, numbers, booleans) are stringified and concatenated into the message
   */
  warn(...args: LoggerArguments) {
    this.#log('warn', ...args);
  }

  /**
   * Log an optional message, optional context object, and optional error at the error level
   *
   * Order of the context object and error is not important, all are valid:
   * - `logger.error('message', contextObject, error)`
   * - `logger.error('message', error, contextObject)`
   * - `logger.error(error, contextObject)`
   *
   * All other arguments (strings, numbers, booleans) are stringified and concatenated into the message
   */
  error(...args: LoggerArguments) {
    this.#log('error', ...args);
  }

  /**
   * Log an optional message, optional context object, and optional error at the fatal level
   *
   * Order of the context object and error is not important, all are valid:
   * - `logger.fatal('message', contextObject, error)`
   * - `logger.fatal('message', error, contextObject)`
   * - `logger.fatal(error, contextObject)`
   *
   * All other arguments (strings, numbers, booleans) are stringified and concatenated into the message
   */
  fatal(...args: LoggerArguments) {
    this.#log('fatal', ...args);
  }

  /** Normalizes the various combinations of logger args that we might receive from call sites */
  static parseLoggerArgs(
    args: LoggerArguments,
    level: LogLevels
  ): { message: string, context: ?mixed, error: ?Error } {
    const isErrorLog: boolean = ERROR_LOG_LEVELS.includes(level);

    // Find the first occurrence of each, if any. Multiple occurrences of Errors or context objects
    // will be ignored, but they will still be captured in `message` in their string form
    const errorArg = args.findIndex((arg) => arg instanceof Error);
    const contextArg = args.findIndex((arg) => typeof arg === 'object' && !(arg instanceof Error));

    const message = Logger.stringifyArgs(args);
    const context = contextArg ? args[contextArg] : undefined;
    let error: ?Error = undefined;

    if (errorArg !== -1) {
      // $FlowIgnore[incompatible-type] Flow thinks it's a tuple, but we know it's an error
      error = args[errorArg];
    } else if (isErrorLog && errorArg === -1) {
      // If we are logging an `error` or `fatal` but no Error was provided in any of the arguments,
      // create a generic error to capture the stack trace
      error = new Error(message);
    }
    return { message, context, error };
  }

  /** Converts any type of log argument into a single string */
  static stringifyArgs(args: LoggerArguments): string {
    return args.reduce((acc, arg) => {
      if (['string', 'number', 'boolean'].includes(typeof arg)) {
        return `${acc} ${String(arg)}`.trimStart();
      }

      if (arg instanceof Error) {
        return `${acc} ${arg.message}`.trimStart();
      }

      return `${acc} ${JSON.stringify(arg, getCircularReplacer()) ?? ''}`.trimStart();
    }, '');
  }
}

export const logger: Logger = new Logger(REMOTE_LOGGING, LOG_LEVEL);
