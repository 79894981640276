// @flow
const scopes: ['reporter', 'viewer', 'worklist', 'workspace'] = Object.freeze([
  'reporter',
  'viewer',
  'worklist',
  'workspace',
]);
const types: ['sys', 'usr'] = Object.freeze(['sys', 'usr']);

export type MetricScope = (typeof scopes)[number];
export type MetricType = (typeof types)[number];

export type MetricOpts = {
  scope: MetricScope,
  type: MetricType,
  name: string,
};

/**
 * Used to make dynamic metrics for a certain scope and type.
 */
export const makeMetric = ({ scope, type, name }: MetricOpts): string => `${scope}.${type}.${name}`;

/**
 * Validates a metric name has the proper naming convention of "scope.type.name" and
 * additionally can validate that a particular scope and type are used.
 */
export const isMetric = (
  name: string = '',
  opts?: { scope?: MetricScope, type?: MetricType }
): boolean => {
  const parts = name.split('.');
  if (parts.length !== 3) return false;
  const [scope, type, metricName] = parts;
  if (!scopes.includes(scope)) return false;
  if (!types.includes(type)) return false;
  if (metricName.length === 0) return false;
  if (opts != null) {
    if (opts.scope != null && opts.scope !== scope) return false;
    if (opts.type != null && opts.type !== type) return false;
  }
  return true;
};

/*
 * Metrics are grouped by component area, as well as by sys and usr.
 * usr metrics are meant for user actions, e.g. button clicked.
 * sys metrics are meant for system actions/info, e.g. websocket opened.
 */

export const viewer: {
  sys: {
    dicomSeriesCacheHit: string,
    dicomSeriesDownload: string,
    nrrdMaskCacheHit: string,
    nrrdMaskDownload: string,
    externalIntegrationError: string,
    externalIntegrationSuccess: string,
    fovia2DScroll: string,
    renderMemory: string,
    renderMetadata: string,
    allInteractable: string,
    slowFrame: string,
    viewportRendering: string,
    webglContextLost: string,
    annotationSaved: string,
    annotationsRendered: string,
    pixelWorkerLoadStack: string,
    pixelWorkerLoadComplete: string,
    pixelWorkerLoadFrameOrigin: string,
  },
  usr: {
    averageScrollingFrameDuration: string,
    seriesDropped: string,
    scrolledToLoadingSlice: string,
  },
} = Object.freeze({
  sys: {
    dicomSeriesCacheHit: 'viewer.sys.dicom_series_cache_hit',
    dicomSeriesDownload: 'viewer.sys.dicom_series_download',
    nrrdMaskCacheHit: 'viewer.sys.nrrd_mask_cache_hit',
    nrrdMaskDownload: 'viewer.sys.nrrd_mask_download',
    externalIntegrationError: 'viewer.sys.external_integration_error',
    externalIntegrationSuccess: 'viewer.sys.external_integration_success',
    fovia2DScroll: 'viewer.sys.fovia_2d_scoll',
    renderMemory: 'viewer.sys.render_memory',
    renderMetadata: 'viewer.sys.render_metadata',
    allInteractable: 'viewer.sys.all_interactable',
    slowFrame: 'viewer.sys.slow_frame',
    viewportRendering: 'viewer.sys.viewport_rendering',
    webglContextLost: 'viewer.sys.webgl_context_lost',
    annotationSaved: 'viewer.sys.annotation_saved',
    annotationsRendered: 'viewer.sys.annotations_rendered',
    pixelWorkerLoadStack: 'viewer.sys.pixel_worker_load_stack',
    pixelWorkerLoadComplete: 'viewer.sys.pixel_worker_load_complete',
    pixelWorkerLoadFrameOrigin: 'viewer.sys.pixel_worker_load_frame_origin',
  },
  usr: {
    averageScrollingFrameDuration: 'viewer.usr.average_scrolling_frame_duration',
    seriesDropped: 'viewer.usr.series_dropped',
    scrolledToLoadingSlice: 'viewer.usr.scrolled_to_loading_slice',
  },
});

export const reporter: {
  sys: {
    dictationDuration: string,
    dictationQueued: string,
    mixPanelIdentifyUser: string,
    mixPanelResetUser: string,
    nvoqHypothesisStableGap: string,
    nvoqTimeToFirstStableText: string,
    nvoqTimeToLastStableText: string,
    nvoqWebSocketTimeToConnect: string,
    nvoqWebSocketTimeConnected: string,
    nvoqWebSocketTimeCreated: string,
    slowAudioProcessing: string,
    stableTextReceived: string,
    textCorrectedByDictation: string,
    textNormalized: string,
    upgradeHeadingKeywordToHeading: string,
    renderMemory: string,
  },
  usr: {
    debugTicket: string,
    recordingStarted: string,
    recordingStopped: string,
    starRating: string,
    submitReport: string,
    submitLinkedCases: string,
    submitReportCancel: string,
    submitAddendum: string,
    submitAddendumCancel: string,
    voiceCommand: string,
    wordsDictated: string,
    charactersTyped: string,
    markApplied: string,
    clearMarksForSelection: string,
    insertPlaceholder: string,
  },
} = Object.freeze({
  sys: {
    dictationDuration: 'reporter.sys.dictation_duration',
    dictationQueued: 'reporter.sys.dictation_queued',
    mixPanelIdentifyUser: 'reporter.sys.mixpanel_identify_user',
    mixPanelResetUser: 'reporter.sys.mixpanel_reset_user',
    nvoqHypothesisStableGap: 'reporter.sys.nvoq_hypothesis_stable_gap',
    nvoqTimeToFirstStableText: 'reporter.sys.nvoq_time_to_first_stable_text',
    nvoqTimeToLastStableText: 'reporter.sys.nvoq_time_to_last_stable_text',
    nvoqWebSocketTimeToConnect: 'reporter.sys.nvoq_websocket_time_to_connect',
    nvoqWebSocketTimeConnected: 'reporter.sys.nvoq_websocket_time_connected',
    nvoqWebSocketTimeCreated: 'reporter.sys.nvoq_websocket_time_created',
    slowAudioProcessing: 'reporter.sys.slow_audio_processing',
    stableTextReceived: 'reporter.sys.stable_text_received',
    textCorrectedByDictation: 'reporter.sys.text_corrected_by_dictation',
    textNormalized: 'reporter.sys.text_normalized',
    upgradeHeadingKeywordToHeading: 'reporter.sys.upgrade_heading_keyword_to_heading',
    renderMemory: 'reporter.sys.render_memory',
  },
  usr: {
    debugTicket: 'reporter.usr.debug_ticket',
    recordingStarted: 'reporter.usr.recording_started',
    recordingStopped: 'reporter.usr.recording_stopped',
    starRating: 'reporter.usr.star_rating',
    submitReport: 'reporter.usr.submit_report',
    submitLinkedCases: 'reporter.usr.submit_linked_cases',
    submitReportCancel: 'reporter.usr.submit_report_cancel',
    submitAddendum: 'reporter.usr.submit_addendum',
    submitAddendumCancel: 'reporter.usr.submit_addendum_cancel',
    voiceCommand: 'reporter.usr.voice_command',
    wordsDictated: 'reporter.usr.words_dictated',
    charactersTyped: 'reporter.usr.characters_typed',
    markApplied: 'reporter.usr.mark_applied',
    clearMarksForSelection: 'reporter.usr.clear_marks_for_selection',
    insertPlaceholder: 'reporter.usr.insert_placeholder',
  },
});

export const worklist: {
  sys: {
    renderMemory: string,
  },
  usr: {
    addToQueue: string,
    removeFromQueue: string,
    viewCase: string,
    readCase: string,
  },
} = Object.freeze({
  sys: {
    renderMemory: 'worklist.sys.render_memory',
  },
  usr: {
    addToQueue: 'worklist.usr.add_to_queue',
    removeFromQueue: 'worklist.usr.remove_from_queue',
    viewCase: 'worklist.usr.view_case',
    readCase: 'worklist.usr.read_case',
  },
});

export const workspace: {
  sys: {
    system: string,
  },
  usr: {
    buttonClick: string,
  },
} = Object.freeze({
  sys: {
    system: 'workspace.sys.system',
  },
  usr: {
    buttonClick: 'workspace.usr.button_click',
  },
});

export const globalContext: {
  workspace: {
    sironaPreview: string,
    featureFlag: (feature: string) => string,
    storageEstimate: string,
  },
  viewer: {
    layout: string,
    windowId: string,
    tool: string,
    windowsOpen: string,
    openWindowsIds: string,
    numberOfRelevantPriors: string,
    totalNumberOfFrames: string,
    viewportDisplayConfigurations: string,
    viewportConfigurations: string,
    urlPixelLoaderOriginCacheStatus: string,
    precacheStatus: string,
    loadMetrics: {
      hangedFramesPerSecond: string,
      unhangedFramesPerSecond: string,
      allFramesPerSecond: string,
    },
    memoryInfo: {
      totalCapacityMb: string,
      source: string,
    },
  },
  patient: {
    modality: string,
    bodyPart: string,
  },
  window: {
    desyncEvent: string,
    spamClickEvent: string,
  },
} = Object.freeze({
  workspace: {
    sironaPreview: 'workspace.sirona_preview',
    featureFlag: (feature: string) => `workspace.feature_flags.${feature}`,
    storageEstimate: 'workspace.storage_estimate',
  },
  viewer: {
    layout: 'viewer.layout',
    windowId: 'viewer.window_id',
    tool: 'viewer.active_tool',
    windowsOpen: 'viewer.windows_open',
    openWindowsIds: 'viewer.open_windows_ids',
    numberOfRelevantPriors: 'viewer.number_of_relevant_priors',
    totalNumberOfFrames: 'viewer.total_number_of_frames',
    viewportDisplayConfigurations: 'viewer.viewport_display_configurations',
    viewportConfigurations: 'viewer.viewport_configurations',
    urlPixelLoaderOriginCacheStatus: 'viewer.url_pixel_loader_origin_cache_status',
    precacheStatus: 'viewer.precache_status',
    memoryInfo: {
      totalCapacityMb: 'viewer.memory.total_capacity_mb',
      source: 'viewer.memory.source',
    },
    loadMetrics: {
      hangedFramesPerSecond: 'viewer.load_metrics.hanged_frames_per_second',
      unhangedFramesPerSecond: 'viewer.load_metrics.unhanged_frames_per_second',
      allFramesPerSecond: 'viewer.load_metrics.all_frames_per_second',
    },
  },
  patient: {
    modality: 'patient.modality',
    bodyPart: 'patient.body_part',
  },
  window: {
    desyncEvent: 'window.desync_event',
    spamClickEvent: 'window.spam_click_event',
  },
});
